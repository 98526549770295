import React, { useCallback, useEffect } from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import bookHandList from "../../../assets/images/book-hand-list.png";
import magnetBlue from "../../../assets/images/magnet-blue.svg";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const Hero = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <section className={`project-hero`}>
      <div className=''>
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: { enable: false, zIndex: 0 },
            background: {
              color: {
                value: "#ffffff00",
              },
            },
            fpsLimit: 30,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff00",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: false,
                opacity: 0.1,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 30,
              },

              opacity: {
                value: 0.5,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.5,
                  sync: false,
                },
              },

              shape: {
                type: ["circle", "image"],
                image: [
                  {
                    src: roundBlur,
                    height: 70,
                    width: 73,
                  },
                  {
                    src: blueCookie,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlueSmall,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlurMed,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: roundBlueLarge,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: magnetBlue,
                    height: 40,
                    width: 43,
                  },
                ],
              },
              size: {
                value: 50,
                random: false,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 10,
                  sync: false,
                },
              },
            },
            detectRetina: false,
          }}
        />
      </div>

      <div className="hero-skew"></div>
      <div className={`wrapper`}>
        <div className={`left`}>
          <h2>Projects</h2>
          <p>
            Our portfolio of successful projects is a testament to our expertise
            in providing top-notch software development, UI/UX design, data
            analytics, and DevOps services. From web applications to mobile
            apps, we have successfully delivered numerous projects across
            various industries. Our team of skilled professionals specializes in
            delivering custom solutions tailored to your unique business needs.
          </p>
          <div className={`btn-mobile`}>
            <Link
              to="/contact-us"
              className="btn-primary btn-large btn-toolbox"
            >
              Get Started
            </Link>
          </div>
        </div>
          <div className={`right`} style={{ zIndex: 10 }}>
            <img src={bookHandList} alt="right" />
          </div>
        </div>
    </section>
  );
};

export default Hero;
