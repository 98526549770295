import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const Error404 = () => {
  return (
    <section className='error-page'>
      <div className='wrapper'>
        <div className='error-content'>
          <h2>404</h2>
          <h5>We can't find that page</h5>
          <p>
            {/* We are fairly sure that page used to be here, but seems to have gone
            missing. We appologize on it's behalf. */}
            I think you just went to a non-existing page in this website. Click
            the home button below to go back to the homepage.
          </p>
          <Link to='/' className='btn-primary btn-large'>
            Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Error404;
