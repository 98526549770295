import React from "react";

import { Helmet } from "react-helmet";
import ApplicationHero from "./hero/application";
import EmptyDiv from "../contactUs/emptyDiv";

const applyCareer = () => {
  return (
    <div className='homepage'>
    <Helmet>
      <title>Contact-Us | Omniswift</title>
    </Helmet>
    <div className='wrapper'>
      <ApplicationHero />
      <EmptyDiv />
    </div>
  </div>
  );
};

export default applyCareer;
