import React, { useCallback, useEffect } from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import handImg from "../../../assets/images/hand-monitor.png";
import magnetBlue from "../../../assets/images/magnet-blue.svg";
import userSystem from "../../../assets/images/user-system.png";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination,
  Autoplay,
  Navigation,
  EffectFlip,
} from "swiper";

import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";
SwiperCore.use([Pagination, Navigation]);
SwiperCore.use([Autoplay, EffectFlip]);
const pagination = {
  el: ".custom-pag1",
  clickable: true,
  renderBullet: function (index, className) {
    // return '<span class="' + className + '">' + "</span>";
    return `<span class="'  ${className} '">  </span>`;
  },
};
const Hero = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <section className={`hero`}>
      {/* <img src={roundBlur} alt="" className="round-blur" />
    <img src={roundBlur} alt="" className="round-blur2" />
    <img src={roundBlueLarge} alt="" className="round-blue-large" />
    <img src={roundBlueLarge} alt="" className="round-blue-large2" />
    <img src={roundBlurMed} alt="" className="round-blur-med" />
    <img src={roundBlurMed} alt="" className="round-blur-med2" />
    <img src={roundBlueSmall} alt="" className="round-blue-small" />
    <img src={roundBlueSmall} alt="" className="round-blue-small2" />
    <img src={blueCookie} alt="" className="blue-cookie" /> */}
      <div className="">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: { enable: false, zIndex: 0 },
            background: {
              color: {
                value: "#ffffff00",
              },
            },
            fpsLimit: 30,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff00",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: false,
                opacity: 0.1,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 30,
              },

              opacity: {
                value: 0.5,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.5,
                  sync: false,
                },
              },

              shape: {
                type: ["circle", "image"],
                image: [
                  {
                    src: roundBlur,
                    height: 70,
                    width: 73,
                  },
                  {
                    src: blueCookie,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlueSmall,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlurMed,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: roundBlueLarge,
                    height: 40,
                    width: 43,
                  },
                  { src: magnetBlue, height: 40, width: 43 },
                ],
              },
              size: {
                value: 50,
                random: false,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 10,
                  sync: false,
                },
              },
              // size: {
              //   value: { min: 1, max: 5 },
              // },
            },
            detectRetina: false,
          }}
        />
      </div>
      <div className="hero-skew"></div>
      <div className={` wrapper`}>
      {/* <div className={`container`}>
        <div className={`left`}>
          <h1>First Impressions Matter</h1>

          <p>
            We can help you convert more prospects into leads and customers
            through our process.
          </p>

          <div className={`subscribe`}>
            <button className='btn-primary btn-large'>Get Started</button>
          </div>
        </div>
        <div className={`right`}>
          <img src={handImg} alt='' />
        </div>
      </div> */}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={pagination}
        navigation={{
          nextEl: ".custom-next1",
          prevEl: ".custom-prev1",
        }}
        className={`container`}
        autoHeight={true}
        centeredSlides={true}
        speed={2000}
        autoplay={{
          delay: 30000,
          disableOnInteraction: false,
        }}
        // autoplay={true}
        // effect='flip'
        // effect='cube'
        // loop
      >
        <SwiperSlide>

          <div className={`container`}>
            <div className={`left`}>
              <h1>About Us</h1>
              <p>
                Welcome to Omniswift Nigeria Limited, where technology meets
                innovation! We are more than just a tech company. We are a team
                of passionate tech enthusiasts who are committed to helping
                businesses and organizations achieve their goals through the
                power of technology.
              </p>
            </div>
            <div className={`right`} style={{ zIndex: 1 }}>
              <img src={handImg} alt="right" />
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className={`container`}>
            <div className={`left`}>
              <h1>Second Impressions Matter</h1>

              <p>
                We can help you convert more prospects into leads and
                customers through our process.
              </p>

              <div className={`subscribe indicator`}>
                <Link to="/contact-us" className="btn-primary btn-large">
                  Get Started
                </Link>
              </div>
            </div>
            <div className={`right`}>
              <img src={handImg} alt="" />
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>

      <div className="custom-pag1"></div>

      {/* <div className='swiper-button-prev'></div> */}
      {/* <div className='swiper-button-next'></div> */}

      {/* <div className='custom-prev1'>
        <Prev />
      </div>
      <div className='custom-next1'>
        <Next />
      </div> */}
      </div>
    </section>
  );
};

export default Hero;
