import React from "react";
import HomeHero from "./hero";
import Design from "./businessess";
import TakePride from "./process";
import { Helmet } from "react-helmet";


const processPage = () => {
  return (
    <div className='homepage'>
        <Helmet>
        <title>Our-Process | Omniswift</title>
      </Helmet>
      <div className='wrapper'>
        <HomeHero />
        <Design />
        <TakePride />
      </div>
    </div>
  );
};

export default processPage;
