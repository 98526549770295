import React from "react";
import ProdHero from "./hero";
import EmptyDiv from "./emptyDiv";
import { Helmet } from "react-helmet";

const contactPage = () => {
  return (
    <div className='homepage'>
      <Helmet>
        <title>Contact-Us | Omniswift</title>
      </Helmet>
      <div className='wrapper'>
        <ProdHero />
        <EmptyDiv />
      </div>
    </div>
  );
};

export default contactPage;
