import React, { useEffect } from "react";
import phoneComic from "../../../assets/images/phone-isomorph.png";
import gallery from "../../../assets/images/gallery-pen.png";
import roundBlurMed from "../../../assets/images/round-non-blur.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const Design = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className="design">
      <div className="blur-image">
        <img src={roundBlurMed} alt="" className="round-blur-med" />
      </div>

      <div data-aos="fade-up" className="wrapper">
        <div data-aos="fade-right" className="left">
          <img src={phoneComic} alt="" />
        </div>
        <div className="right">
          <div className="content-design">
            <img src={gallery} alt="" />

            <h2>Design</h2>
            <p>
              At Omniswift, design is not just a department. It's a
              <span> way of thinking and working</span> , based on the belief
              that design is not just how something looks, but also how it
              works.
            </p>
            <p>
              It's the driving force behind product development, service
              offerings, customer experience and company culture. We believe
              that great design starts with <span> empathy</span> . Who are your
              users? What do they value? How do they behave?
            </p>
            <Link
              to="/products-services"
              className="btn-primary btn-large btn-toolbox"
            >
              Showcases
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Design;
