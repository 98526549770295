import React from "react";

const Input = ({
  innerLabel,
  wrapperClass,
  labelText,
  optionalText,
  children,
  id,
  required,
  clicked,
  iconSrc,
  ...props
}) => {
  return (
    <>
      {/* {labelText && <label htmlFor={id}>{labelText}</label>} */}
      {labelText && (
        <div className='label-wrapper'>
          <label htmlFor={id}>{labelText}</label>
          <p>{optionalText}</p>
        </div>
      )}

      {innerLabel && (
        <div className={`input-wrapper ${wrapperClass ? wrapperClass : ""}`}>
          <input {...props} id={id} required={required} />
          <label htmlFor={id} onClick={clicked}>
            {children}
          </label>
        </div>
      )}

      {!innerLabel && (
        <div className={`input-wrapper ${wrapperClass ? wrapperClass : ""}`}>
          <div className='input-icon'>
            <img src={iconSrc} alt='' />
          </div>
          <input {...props} id={id} required={required} />
        </div>
      )}
    </>
  );
};

export default Input;
