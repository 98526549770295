import React, { useEffect } from "react";
import microsoft from "../../../assets/images/micro-soft.svg";
import hp from "../../../assets/images/HP.svg";
import cypher from "../../../assets/images/cypher.svg";
import mindlogicx from "../../../assets/images/mindlogicX.svg";
import ontec from "../../../assets/images/ontec.svg";
import utilibill from "../../../assets/images/utilibill.svg";
import roundBlurMed from "../../../assets/images/round-non-blur.svg";
import roundBlur from "../../../assets/images/round-blur.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import "./styles.scss";

const TakePride = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className="take-pride">
      <div className="blur-image">
        <img src={roundBlurMed} alt="" className="round-blur-med" />
      </div>
      <div className="blur-image-2">
        <img src={roundBlur} alt="" className="round-blur" />
      </div>
      <div data-aos="fade-up" className="wrapper">
        <div className="content">
          <p>Strategy Partnership</p>
          <h2>We take pride in designing extraordinary products for you</h2>
          <p>
            With our honest approach, we wish to create a trustworthy
            environment, where our promises towards the clients are always kept
          </p>
        </div>
        <div className="companies-logos">
          <div className="logo-scroll">
            <img src={microsoft} alt="" className="logo" />
            <img src={hp} alt="" className="logo" />
            <img src={cypher} alt="" className="logo" />
            <img src={mindlogicx} alt="" className="logo" />
            <img src={ontec} alt="" className="logo" />
            <img src={utilibill} alt="" className="logo" />
          </div>
        </div>
        <div className="btn-container">
          <Link to="/contact-us" className="btn-primary btn-large btn-toolbox">
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TakePride;
