import React from "react";
import roundBlurMed from "../../../assets/images/round-non-blur.svg";
import roundBlur from "../../../assets/images/round-blur.svg";

import "./styles.scss";

const EmptyDiv = () => {
  return (
    <section className='empty-div'>
      <div className='blur-image'>
        <img src={roundBlur} alt='' className='round-blur' />
      </div>
      <div className='blur-image-2'>
        <img src={roundBlurMed} alt='' className='round-blur-med' />
      </div>
    </section>
  );
};

export default EmptyDiv;
