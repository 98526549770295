import React, { useEffect } from "react";
import chatGreen from "../../../assets/images/chat-green.png";
import chartGreen from "../../../assets/images/chart-green.png";
import paperGreen from "../../../assets/images/paper-green.png";
import settingGreen from "../../../assets/images/setting-green.png";
import shieldGreen from "../../../assets/images/shield-green.png";
import roundBlurMed from "../../../assets/images/round-non-blur.svg";
import roundBlur from "../../../assets/images/round-blur.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

import "./styles.scss";

const Process = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className="process">
      <div className="blur-image">
        <img src={roundBlur} alt="" className="round-blur" />
      </div>
      <div className="blur-image-2">
        <img src={roundBlurMed} alt="" className="round-blur-med" />
      </div>
      <div className="wrapper">
        <div data-aos="fade-up" className="content">
          <h2>Process</h2>
          <p>
            Whenever we work on a project,{" "}
            <span> we follow these five steps</span> everyday to improve
            ourselves and the work we do.
          </p>
        </div>
        <div className="process-container">
          <div data-aos="zoom-in" className="process-card">
            <img src={paperGreen} alt="" className="logo" />
            <p>Ship</p>
          </div>

          <div data-aos="zoom-in" className="process-card">
            <img src={chatGreen} alt="" className="logo" />
            <p>Listen</p>
          </div>

          <div data-aos="zoom-in" className="process-card">
            <img src={chartGreen} alt="" className="logo" />
            <p>Strategies</p>
          </div>

          <div data-aos="zoom-in" className="process-card">
            <img src={settingGreen} alt="" className="logo" />
            <p>Do</p>
          </div>

          <div data-aos="zoom-in" className="process-card">
            <img src={shieldGreen} alt="" className="logo" />
            <p>Check</p>
          </div>
        </div>
        <div className="btn-container">
          <Link to="/contact-us" className="btn-primary btn-large btn-toolbox">
            Contact Us
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Process;
