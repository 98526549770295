import React, { useEffect } from "react";
import briefPad from "../../../assets/images/brief-pad.png";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const OurToolBox = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className="our-toolbox">
      <div className="blur-image">
        <img src={roundBlurMed} alt="" className="round-blur-med" />
      </div>

      <div className="wrapper">
        <div data-aos="fade-up" className="content">
          <img src={briefPad} alt="" />

          <h2>Our Toolbox</h2>
          <p>
            We are a full-stack web development team with skills ranging from
            <span> Laravel</span>,<span> VueJs</span> and <span> ReactJS</span>.
            Our work is highly focused on both business strategy and technical
            execution.
          </p>
          <p>
            We take pride in our work and so much more in the conversations we
            have about it. We’d love to talk shop, over coffee, with you.
          </p>
          <Link to="/our-process" className="btn-primary btn-large btn-toolbox">
            Our Process
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OurToolBox;
