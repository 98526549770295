import React, { useEffect } from "react";
import bulbComic from "../../../assets/images/hand-bulb.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const Businesses = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className="businessess">
      <div data-aos="fade-up" className="wrapper">
        <div data-aos="fade-right" className="left">
          <img src={bulbComic} alt="" />
        </div>
        <div className="right">
          <div className="content">
            <h2>Businesses</h2>
            <p>
              <span> Do you want to grow your business?</span>
            </p>
            <p>
              Omniswift is about turning data into better decisions so you know
              what to do next. We develop technology platforms that help
              <span> business grow </span> by making them <span> smarter </span>{" "}
              and <span>more efficient </span>, and giving the right people at
              your company the information they need to make better decisions.
            </p>
            <div className="btn-div">
              <Link
                to="/contact-us"
                className="btn-primary btn-large btn-toolbox"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Businesses;
