import React, { useState, useEffect, useCallback } from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import magnetBlue from "../../../assets/images/magnet-blue.svg";
import { useDropzone } from "react-dropzone";
import { Slider } from "@material-ui/core";
import Input from "../../ui/customInput";
import Textarea from "../../ui/customTextarea";
import Checkbox from "../../ui/customCheckbox";
import user from "../../../assets/images/Profile.svg";
import groupUser from "../../../assets/images/group-user.svg";
import message from "../../../assets/images/Message.svg";
import phone from "../../../assets/images/Call.svg";
import textPen from "../../../assets/images/Edit.svg";
import downloadIcon from "../../../assets/images/Download.svg";
import settingIcon from "../../../assets/images/Setting.svg";
import timeIcon from "../../../assets/images/Time-Circle.svg";
import walletIcon from "../../../assets/images/Wallet.svg";
import phone2 from "../../../assets/images/Call2.svg";
import location from "../../../assets/images/Location.svg";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AOS from "aos";
import "aos/dist/aos.css";
// import { useHistory } from "react-router-dom";
// import AlertBox from "../../ui/alerts/error";
// import SuccessBox from "../../ui/alerts/success";
import Swal from "sweetalert2";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import "./styles.scss";

const timeline = [
  {
    value: 0,
    scaledValue: 0,
    label: "0",
  },

  {
    value: 30,
    scaledValue: 3,
    label: "3+ M",
  },

  {
    value: 60,
    scaledValue: 6,
    label: "6+ M",
  },

  {
    value: 90,
    scaledValue: 9,
    label: "9+ M",
  },
  {
    value: 120,
    scaledValue: 12,
    label: "12+ M",
  },
];

const budget = [
  {
    value: 0,
    scaledValue: 0,
    label: "₦0",
  },
  {
    value: 25,
    scaledValue: 1000,
    label: "₦1k",
  },
  {
    value: 50,
    scaledValue: 10000,
    label: "₦10k",
  },
  {
    value: 75,
    scaledValue: 100000,
    label: "₦100k",
  },
  {
    value: 100,
    scaledValue: 1000000,
    label: "₦100k+",
  },
];

// Money Formatter

const scale = (value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = budget[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }

  const nextMark = budget[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  return remainder * increment + previousMark.scaledValue;
};

function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num < 1000) {
    return "₦" + ((num / 100) * 100).toFixed(0); //  convert to H for number if value < 1000,
  }
}

// Week Formatter

const scale2 = (value) => {
  const previousMarkIndex = Math.floor(value / 30);
  const previousMark = timeline[previousMarkIndex];
  const remainder = value % 30;

  if (remainder === 0) {
    return previousMark.scaledValue;
  }

  const nextMark = timeline[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 30;
  return remainder * increment + previousMark.scaledValue;
};

function numFormatter2(num) {
  if (num > 0 && num <= 12) {
    return (num / 1).toFixed(0) + "m+";
  } else if (num < 1) {
    return (num / 1 + 1).toFixed(0) + "wk+";
  }
}

const Hero = () => {
  // const history = useHistory();
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const [budgetValue, setBudgetValue] = React.useState(0);
  const [budgetForm, setBudgetForm] = React.useState("");
  const [timelineValue, setTimelineValue] = React.useState(0);
  const [timelineForm, setTimelineForm] = React.useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [doc, setDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [checks, setChecks] = useState({
    frontend: false,
    backend: false,
    uiux: false,
  });

  const handleBudgetChange = (event, newValue) => {
    setBudgetValue(newValue);
  };

  const handleTimelineChange = (event, newValue) => {
    setTimelineValue(newValue);
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;

    setChecks({
      ...checks,
      [name]: checked,
    });
  };

  const Dropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: "/*",
      onDrop: (acceptedFiles) => {
        setDoc(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const files = doc.map((file) => {
      let kb = file.size / 1000;
      let kbSize = Math.round(kb);

      return kbSize > 5000 ? (
        <li className="error-size">File should not exceed 5 mb</li>
      ) : (
        <li key={file.path}>
          {file.path} - {kbSize} kb
        </li>
      );
    });

    return (
      <div className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Send us your file</p>
          <img src={downloadIcon} alt="" />
          <div className="uploadedFile">
            <ul>{files}</ul>
          </div>
        </div>
      </div>
    );
  };

  const showAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Message sent!",
      icon: "success",
      confirmButtonText: "OK",
    }).then(function () {
      // Redirect the user
      // window.location.href = "/new-page";
      window.location.reload();
    });
  };

  const showError = () => {
    Swal.fire({
      title: "Oops",
      text: "An error occurred!",
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  // console.log(fields);
  console.log(checks);
  console.log(timelineForm);
  console.log(budgetForm);
  console.log(doc);
  console.log(doc[0]);
  const acceptPlus =
    /^((\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const notAcceptPlus =
  // /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const intlPhoneNumStandard = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      message: "",
      other: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Required *"),
      companyName: Yup.string().required("Required *"),
      email: Yup.string().required("Required *"),
      phoneNumber: Yup.string().matches(
        acceptPlus,
        "Phone number is not valid *"
      ),
      message: Yup.string().required("Required *"),
      other: Yup.string(),
    }),

    onSubmit: async (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("fullName", values.fullName);
        formData.append("companyName", values.companyName);
        formData.append("email", values.email);
        formData.append("message", values.message);
        formData.append("other", values.other);
        formData.append("phoneNumber", values.phoneNumber);

        formData.append("frontend", checks.frontend);
        formData.append("backend", checks.backend);
        formData.append("uiux", checks.uiux);

        formData.append("timeline", timelineForm);
        formData.append("budget", `${budgetForm}`);

        formData.append("file", doc[0] === undefined ? "" : doc[0]);

        console.log(formData);

        const res = await axios.post(
          "https://serene-brook-04052.herokuapp.com/api/contact/send",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        showAlert();

        setIsChecked(false);

        setChecks({
          frontend: false,
          backend: false,
          uiux: false,
        });

        setDoc([]);
        // history.push(`/`);
        // setSuccess("Message sent!");
        setSuccess(res.data.data);
        console.log(res.data.data);
      } catch (err) {
        setError(err.message);
        showError();
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    doc.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [doc]);

  useEffect(() => {
    setTimelineForm(scale2(timelineValue));
    setBudgetForm(scale(budgetValue));
  }, [budgetValue, timelineValue]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <section className={`contact-hero`}>
      {/* <img src={roundBlur} alt="" className="round-blur" />
      <img src={roundBlueLarge} alt="" className="round-blue-large" />
      <img src={roundBlurMed} alt="" className="round-blur-med" />
      <img src={roundBlueSmall} alt="" className="round-blue-small" />
      <img src={blueCookie} alt="" className="blue-cookie" /> */}
      <div className="">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: { enable: false, zIndex: 0 },
            background: {
              color: {
                value: "#ffffff00",
              },
            },
            fpsLimit: 30,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff00",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: false,
                opacity: 0.1,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 30,
              },

              opacity: {
                value: 0.5,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.5,
                  sync: false,
                },
              },

              shape: {
                type: ["circle", "image"],
                image: [
                  {
                    src: roundBlur,
                    height: 70,
                    width: 73,
                  },
                  {
                    src: blueCookie,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlueSmall,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlurMed,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: roundBlueLarge,
                    height: 40,
                    width: 43,
                  },
                  { src: magnetBlue, height: 40, width: 43 },
                ],
              },
              size: {
                value: 50,
                random: false,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 10,
                  sync: false,
                },
              },
              // size: {
              //   value: { min: 1, max: 5 },
              // },
            },
            detectRetina: false,
          }}
        />
      </div>

      <div className="hero-skew"></div>

      <div className={` wrapper`}>
        <div className={`left`}>
          <h2>Start a Project</h2>
          <p>
            Feel free to send us a tweet
            <span>
              <a
                href="https://twitter.com/omniswift"
                target="_blank"
                rel="noreferrer"
              >
                @omniswift{" "}
              </a>
            </span>
            , but if you want to tell us something more substantial, please
            complete this form. We'll get back to you as soon as we can.
          </p>
        </div>
      </div>
      <div data-aos="fade-up" className="form-container wrapper">
        <form onSubmit={formik.handleSubmit}>
          <h3>Contact details</h3>
          <div className="form-grid">
            <div className="formGroup">
              <Input
                labelText="Your name"
                id="fullName"
                // required
                type="text"
                name="fullName"
                placeholder="First and last name"
                iconSrc={user}
                onChange={formik.handleChange}
                value={formik.values.fullName}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <p className="error-msg">{formik.errors.fullName}</p>
              ) : null}
            </div>
            <div className="formGroup">
              <Input
                labelText="Company name"
                id="companyName"
                // required
                type="text"
                name="companyName"
                placeholder="Company,Inc"
                iconSrc={groupUser}
                onChange={formik.handleChange}
                value={formik.values.companyName}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <p className="error-msg">{formik.errors.companyName}</p>
              ) : null}
            </div>
            <div className="formGroup">
              <Input
                labelText="Email adress"
                id="email"
                // required
                type="text"
                name="email"
                placeholder="you@example.com"
                iconSrc={message}
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="error-msg">{formik.errors.email}</p>
              ) : null}
            </div>
            <div className="formGroup">
              <Input
                labelText="Phone number"
                id="phoneNumber"
                optionalText="optional"
                // required
                type="text"
                name="phoneNumber"
                placeholder="(123) 456-7890"
                iconSrc={phone}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
              />
              {formik.errors.phoneNumber ? (
                <p className="error-msg">{formik.errors.phoneNumber}</p>
              ) : null}
            </div>
          </div>
          <div className="textarea">
            <Textarea
              labelText="Message or project details"
              id="message"
              // required
              name="message"
              placeholder="Type your message..."
              iconSrc={textPen}
              onChange={formik.handleChange}
              value={formik.values.message}
              onBlur={formik.handleBlur}
            />
            {formik.touched.message && formik.errors.message ? (
              <p className="error-msg">{formik.errors.message}</p>
            ) : null}
          </div>
          <h3>Project details</h3>
          <div className="service-needed">
            <div className="check-title">
              <span>
                <img src={settingIcon} alt="" />
              </span>
              <p>Service needed</p>
            </div>
            <div className="checkbox-group-wrapper">
              <div className="checkbox-group">
                <Checkbox
                  checkText={"Front-end Development"}
                  htmlFor="service"
                  name="frontend"
                  onChange={handleCheckChange}
                />
              </div>

              <div className="checkbox-group">
                <Checkbox
                  checkText={"Back-end Development"}
                  htmlFor="service"
                  name="backend"
                  onChange={handleCheckChange}
                />
              </div>

              <div className="checkbox-group">
                <Checkbox
                  checkText={"UI/UX Design"}
                  htmlFor="service"
                  name="uiux"
                  onChange={handleCheckChange}
                />
              </div>

              <div className="checkbox-group">
                <Checkbox
                  checkText={"Other"}
                  htmlFor="service"
                  name="service"
                  checked={isChecked}
                  onChange={handleOnChange}
                />
              </div>
            </div>
          </div>
          {isChecked && (
            <div className="textarea">
              <Textarea
                // labelText="Message or project details"
                id="other"
                // required
                name="other"
                placeholder="Type your need..."
                iconSrc={textPen}
                onChange={formik.handleChange}
                value={formik.values.other}
              />
            </div>
          )}

          <div className="slider-wrapper">
            <div className="slider-item">
              <div className="slider-head">
                <div className="check-title">
                  <span>
                    <img src={timeIcon} alt="" />
                  </span>
                  <p>Timeline</p>
                </div>
                <p>optional</p>
              </div>

              <Slider
                // aria-label='Always visible'
                // getAriaValueText={valuetext}
                // valueLabelDisplay='on'
                // style={{ width: 300 }}
                aria-label="Default"
                defaultValue={30}
                step={10}
                marks={timeline}
                valueLabelDisplay="auto"
                // Added this with the new scaling
                value={timelineValue}
                min={0}
                max={120}
                valueLabelFormat={numFormatter2}
                scale={scale2}
                onChange={handleTimelineChange}
              />
            </div>

            <div className="slider-item">
              <div className="slider-head">
                <div className="check-title">
                  <span>
                    <img src={walletIcon} alt="" />
                  </span>
                  <p>Budget</p>
                </div>
                <p>optional</p>
              </div>

              <Slider
                aria-label="Temperature"
                // defaultValue will not work because, this is a controlled component in the sense that it is controlled by react . it's value is stored in a [state]
                defaultValue={100}
                step={1}
                marks={budget}
                valueLabelDisplay="auto"
                // Added this with the new scaling
                value={budgetValue}
                min={0}
                max={100}
                valueLabelFormat={numFormatter}
                scale={scale}
                onChange={handleBudgetChange}
              />
            </div>
          </div>

          <div className="formGroupFile">
            <div className="file-head">
              <div className="file-title">
                <span>
                  <img src={walletIcon} alt="" />
                </span>
                <p>File attachments</p>
              </div>
              <p>optional</p>
            </div>

            <Dropzone />
          </div>
          {/* {error && <AlertBox>{"An error occurred !"}</AlertBox>} */}
          {/* {success && <SuccessBox>{"Message sent !"}</SuccessBox>} */}
          <div className="btn-container">
            <button className="btn-primary btn-large btn-toolbox">
              {loading ? "Sending..." : "Send"}
            </button>
          </div>

          <div className="address-wrapper">
            <div className="icon-text">
              <span>
                <img src={location} alt="" />
              </span>
              <p>No 12, Matadi Street, Wuse Zone 3, Abuja</p>
            </div>
            <div className="icon-text">
              <span>
                <img src={phone2} alt="" />
              </span>
              <p>+2349044800700</p>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Hero;
