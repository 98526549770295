import slantN from '../../../assets/images/slant-n.png'

let PROJECTITEMS;

PROJECTITEMS = [
    {
        id: 1,
        nom: '01',
        title: "Date Warehouse and BI",
        content:
          "Our data warehouse and Business Intelligence solution that leverages the power of Microsoft Power BI. This solution provides business with real-time insights into their data, allowing them to make more informed decisions and drive growth.",
        image: slantN,
      },
      {
        id: 2,
        nom: '02',
        title: "School and Learning Management System",
        content:
          "Our school management system that has transformed the way educational institutions operate. This system includes application portals, and integration with payment systems, allowing for seamless communication and streamlined processes. With billions of naira processed and thousands of students using the system, it has become a game changer for our clients.",
          image: slantN,
        },
      {
        id: 3,
        nom: '03',
        title: "CRM Software",
        content:
          "Our custom CRM tool that has been tailored to meet the specific needs of a large energy retail chain. This tool includes features such as customer segmentation, sales forecasting, and automated marketing campaigns.",
          image: slantN,
        },
      {
        id: 4,
        nom: '04',
        title: "CIS and Billing System",
        content:
         `Our customer information system and billing system for an energy utility. This system has helped the utility company manage their customer data and billing processes more efficiently, resulting in improved customer satisfaction and lower operational cost.
         These are just a few examples of the types of software products that we’ve worked on. Each project was carefully crafted to meet the unique needs of our clients, and we’re proud of the results that we’ve achieved.`,
          image: slantN,
        },
]
export default PROJECTITEMS