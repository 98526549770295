import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Facebook } from "../../../assets/images/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/images/instagram.svg";
import { ReactComponent as Linkedin } from "../../../assets/images/linkedin.svg";
import { ReactComponent as Twitter } from "../../../assets/images/twitter.svg";

import "./styles.scss";

const Nav = ({ isOpen }) => {
  return (
    <nav className={`nav ${isOpen ? "show" : ""}`} id='navbar'>
      <ul className='navLinks'>
        <li>
          <NavLink to='/' activeClassName='active' exact={true}>
            Showcases
          </NavLink>
        </li>
        <li>
          <NavLink to='/about' activeClassName='active'>
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink to='/projects' activeClassName='active'>
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink to='/products-services' activeClassName='active'>
            Products and Services
          </NavLink>
        </li>
        <li>
          <NavLink to='/our-process' activeClassName='active'>
            Our Process
          </NavLink>
        </li>
        {/* <li>
          <NavLink to='/careers' activeClassName='active'>
            Careers
          </NavLink>
        </li> */}
        <li>
          <NavLink to='/contact-us' activeClassName='active'>
            Contact Us
          </NavLink>
        </li>
      </ul>

      <div className='nav-end'>
        <div className='socials'>
          <a
            href='https://www.facebook.com/omniswift'
            target='_blank'
            rel='noreferrer'
            className='social-link'
          >
            <Facebook />
          </a>
          <a
            href='https://www.linkedin.com/company/omniswift/?originalSubdomain=ng'
            target='_blank'
            rel='noreferrer'
            className='social-link'
          >
            <Linkedin />
          </a>
          <a
            href='https://twitter.com/omniswift'
            target='_blank'
            rel='noreferrer'
            className='social-link'
          >
            <Twitter />
          </a>
          <a
            href='https://www.instagram.com/omniswiftltd/'
            target='_blank'
            rel='noreferrer'
            className='social-link'
          >
            <Instagram />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
