import { Switch, Redirect } from "react-router-dom";
import AppRoute from "./hoc/appRoute";
import RenderTop from "./utils/RenderTop";

// layouts
import LandingLayout from "./layouts/landing";

// pages
import { HomePage } from "./pages/home";
import { AboutPage } from "./pages/about";
import { ProjectsPage } from "./pages/projectPage";
import { ProdPage } from "./pages/prodService";
import { ProcessPage } from "./pages/ourProcess";
import { ContactPage } from "./pages/contactUs";
import { PrivacyPage } from "./pages/privacyPolicy";
import { TermsPage } from "./pages/termsCondition";
import { ErrorPage } from "./pages/error404";
import { CareerPage } from "./pages/careers";
import { Application } from "./pages/careers/[id]";

function App() {
  return (
    <>
      <RenderTop />
      <Switch>
        <AppRoute exact path="/" component={HomePage} layout={LandingLayout} />
        <AppRoute
          exact
          path="/products-services"
          component={ProdPage}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/about"
          component={AboutPage}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/projects"
          component={ProjectsPage}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/our-process"
          component={ProcessPage}
          layout={LandingLayout}
        />
         {/* <AppRoute
          exact
          path="/careers"
          component={CareerPage}
          layout={LandingLayout}
        /> */}
         <AppRoute
          exact
          path="/careers/:id"
          component={Application}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/contact-us"
          component={ContactPage}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/privacy-policy"
          component={PrivacyPage}
          layout={LandingLayout}
        />
        <AppRoute
          exact
          path="/terms-and-conditions"
          component={TermsPage}
          layout={LandingLayout}
        />

        <AppRoute
          exact
          path="**"
          component={ErrorPage}
          layout={LandingLayout}
        />

        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
