import React from "react";
import Banner from "./banner";
import { Helmet } from "react-helmet";


const errorPage = () => {
  return (
    <div className='homepage'>
         <Helmet>
        <title>Error-404 | Omniswift</title>
      </Helmet>
      <div className='wrapper'>
        <Banner />
      </div>
    </div>
  );
};

export default errorPage;
