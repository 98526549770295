import React from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";

import "./styles.scss";

const Hero = () => {
  return (
    <section className={`privacy-hero`}>
      <img src={roundBlur} alt='' className='round-blur' />
      <img src={roundBlueLarge} alt='' className='round-blue-large' />
      <img src={roundBlurMed} alt='' className='round-blur-med' />
      <img src={roundBlueSmall} alt='' className='round-blue-small' />
      <img src={blueCookie} alt='' className='blue-cookie' />

      <div className='hero-skew'></div>

      <div className={` wrapper`}>
        <div className={`left`}>
          <h2>Privacy Policy</h2>
        </div>
      </div>
    
    </section>
  );
};

export default Hero;
