import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const ProjectCard = ({ id, image, nom, title, content, ...props }) => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div data-aos="zoom-in" className="project-card" key={id}>
      <img src={image} alt="" className="gloss-icon" />
      <div className="project-content-container">
        <p className='gray-matter'>{nom}</p>
        <h3>{title}</h3>
        <p style={{maxWidth:'100%'}}>{content}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
