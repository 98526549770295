import briefPad from "../../../assets/images/brief-pad.png";
import docPie from "../../../assets/images/doc-pie.png";
import gallery from "../../../assets/images/gallery-glossy.png";
import user from "../../../assets/images/user-glossy.png";
import slant from "../../../assets/images/slant-n.png";
import papperMorph from "../../../assets/images/papper-morph.png";

let SERVICES;

SERVICES = [
  {
    id: 1,
    title: "Software Development",
    content:
      "We craft clean code in symphony with your design. We are a small team that specializes in modern and clean web applications. When we create an application, we make sure it looks great and runs smoothly.",
    image: slant,
  },
  {
    id: 2,
    title: "Blockchain",
    content:
      "Omniswift’s solution offers powerful, up-to-date dashboards that offer easy-to-understand visualizations, allowing you to monitor and interpret your business data.",
    image: docPie,
  },
  {
    id: 3,
    title: "DevOps",
    content:
      "We understand the rush of the modern world. We’ve been helping companies scale their development and operations and we think we can add even more value to your company!",
    image: gallery,
  },
  {
    id: 4,
    title: "Utility Mgt Systems",
    content:
      "From concept to commissioning, we provide a seamless and comprehensive solution for managing your meters, assets and infrastructure.",
    image: briefPad,
  },
  {
    id: 5,
    title: "MS 365 and MS Teams",
    content:
      "Microsoft 365 and Microsoft Teams are a collaboration hub that will help you work like never before. Share your ideas, get it done fast, and stay connected on the go.",
    image: user,
  },
  {
    id: 6,
    title: "Data Blog",
    content:
      "Omniswift’s solution offers powerful, up-to-date dashboards that offer easy-to-understand visualizations, allowing you to monitor and interpret your business data.",
    image: papperMorph,
  },
  {
    id: 7,
    title: "Cloud Services",
    content:
      "The importance of cloud services is evident in the success of every business in its domain. We connect the lines between various innovative technologies like SaaS (Software as a Service), PaaS (Platform as a Service), and IaaS...",
    image: briefPad,
  },
  {
    id: 8,
    title: "Strategic Consulting",
    content:
      "Since your business varies based on market size and consumer choices, we offer personalized consulting services to our clients. The customized consultation enables a strategic approach to tackle any hurdle stopping your enterprise from success.",
    image: user,
  },
  {
    id: 9,
    title: "User Experience (UX)",
    content:
      "The expert team at Omniswift enables our IT solutions to adapt to various devices through cutting-edge user experience. An aesthetically pleasing user interface combined with efficient software or website makes up the best user experience.",
    image: papperMorph,
  },
];

export default SERVICES;
